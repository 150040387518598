.rn_modal_time {
    position: absolute;
    width: 350px;
    height: 160px;
    right: -45%;
    top: -30px;
    z-index: 9999;
    background: #ffffff;
    box-shadow: 5px 10px 34px 4px #00000026;
    padding: 1.2rem;
}