#mplan-body {
  position: static;
  overflow-x: scroll;
  padding-top: 0;
  margin-bottom: 50px;
  height: calc(100% - 100px);

}

@media all and (max-width: 1370px) {
  #mplan-body {
    height: 70%
  }
}


